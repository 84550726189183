import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import {api} from '../../config/axios';
import { marcarURL } from '../../config/functions';
import { render, Printer, Text, Cut, Br, Row, Line } from 'react-thermal-printer';
import Swal from 'sweetalert2';

// ICONOS
import PrintIcon from '@mui/icons-material/Print';

//COMPONENTES
import Header from '../../components/Header';
import MiTabla from '../../components/plantilla/MiTabla';

const ResumenDelDia = () => {

    // ===============================================
    // FUNCION PARA BUSCAR INVENTARIO
    // ===============================================

    const [data, setData] = useState([]);

    const buscarOrdenes = async () => {

        const response = await api.get('ordenes/cerradas/hoy');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columnsOrders = [

        {
            field: "id",
            headerName: "Orden",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
            renderCell: (params) => `#${params.value}`
        },
        {
            field: "num_mesa",
            headerName: "Mesa",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "total",
            headerName: "Total",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columnsArticulos = [

        {
            field: "articulo",
            headerName: "Articulo",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "precio",
            headerName: "Precio",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
            renderCell: (params) => `$${params.value}`
        },
        {
            field: "cantidad",
            headerName: "Cantidad",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [articulos, setArticulos] = useState({});

    const handleRowClick = (params) => {

        setArticulos(JSON.parse(params.row.articulos));

    }

    // ===============================================
    // FUNCION PARA IMPRIMIR TICKET
    // ===============================================

    const imprimirTicket = async () => {

        if(data.length === 0){

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No hay ordenes para imprimir!',
            });

            return;

        }

        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0'); // Día con dos dígitos
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Mes con dos dígitos (0-indexado)
        const año = hoy.getFullYear(); // Año con cuatro dígitos
        const currentDate = `${dia}/${mes}/${año}`;

        try {

            const ticket = await render(

                <Printer type='epson'>

                    <Text align="center" bold={true} size={{ width: 3, height: 3 }} >CILANTRO</Text>
                    <Br />
                    <Line character="=" />
                    <Text align="center" >Resumen ${currentDate}</Text>
                    <Line character="=" />
                    <Br />

                    {/* RECORREMOS LOS ARTICULOS DE LA ORDEN */}

                    {
                        data.map((orden, index) => {

                            // VALIDAMOS SI HAY ARTICULOS

                            const jsonArticulos = orden.articulos && orden.articulos.trim() !== '' 
                            ? JSON.parse(orden.articulos) 
                            : [];

                            return(

                                <React.Fragment key={index}>

                                    {/* NUMERO DE ORDEN */}
                                
                                    <Text align="center">
                                        Orden #{orden.id} - Mesa {orden.num_mesa}
                                    </Text>

                                    <Br />

                                    {/* RECORREMOS LOS ARTICULOS */}

                                    {
                                        jsonArticulos.length > 0 && jsonArticulos.map((articulo, index2) => {

                                            return(

                                                <Row 
                                                    key={`${articulo.id}-${index2}`}
                                                    left={`${articulo.cantidad} - ${articulo.articulo}`}
                                                    right={`$${articulo.precio}`}
                                                />

                                            )

                                        })
                                    }

                                    <Br />

                                    {/* TOTAL DE ORDEN */}

                                    <Text align="center">
                                        Total: {orden.total}
                                    </Text>

                                    <Br />

                                </React.Fragment>

                            )

                        })
                    }

                    <Cut />

                </Printer>

            )

            const port = await window.navigator.serial.requestPort();
            
            try {

                await port.open({ baudRate: 9600 });

            } catch( e ) {

                console.log(e)

            }

            const writer = port.writable?.getWriter();

            if (writer != null) {

                // SI SE MANDA A IMPRIMIR EL TICKET NADA MAS IMPRIMIMOS Y REGRESAMOS

                await writer.write(ticket);
                
                writer.releaseLock();

            }
             
        } catch (error) {
         
            console.log(error);
            
        }

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarOrdenes();

        marcarURL('resumen-del-dia');

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            <Grid container spacing={1}>

                {/* HEADER */}

                <Grid item xs={10}>

                    <Header title="Resumen del Dia" subtitle=""/>

                </Grid>

                {/* BOTON PARA IMPRIMIR VENTA DEL DIA */}

                <Grid item xs={2}>

                    <Button
                        variant="contained"
                        color="info"
                        startIcon={<PrintIcon />}
                        onClick={imprimirTicket}
                        sx={{
                            float: 'right',
                            color: 'white'
                        }}
                    >
                        Imprimir Resumen
                    </Button>

                </Grid>

                {/* HEADER TABLA ORDEN */}

                <Grid item xs={6}>

                    <Typography
                        variant="h2"
                        textAlign="center"
                    >
                        Ordenes
                    </Typography>

                </Grid>

                {/* HEADER TABLA ARTICULOS */}

                <Grid item xs={6}>

                    <Typography
                        variant="h2"
                        textAlign="center"
                    >
                        Articulos
                    </Typography>

                </Grid>

                {/* TABLA DE ORDENES */}

                <Grid item xs={6}>

                    {/* TABLA */}

                    <MiTabla
                        data={data}
                        columns={columnsOrders}
                        rowClick={handleRowClick}
                        margin='0'
                    />

                </Grid>

                {/* TABLA DE ARTICULOS */}

                <Grid item xs={6}>

                    {/* TABLA */}

                    <MiTabla
                        data={articulos}
                        columns={columnsArticulos}
                        rowClick={handleRowClick}
                        margin='0'
                    />

                </Grid>

            </Grid>

            

        </Box>

    )

}

export default ResumenDelDia