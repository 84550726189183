
import { SubMenu } from "react-pro-sidebar";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListIcon from '@mui/icons-material/List';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIcon from '@mui/icons-material/Assignment';

import Item from "../componentes/Item";

const AdminSidebar = ({ selected, setSelected }) => {

    return (

        <>
        
        {/* DASHBOARD */}

        <Item
            title="Dashboard"
            to="/dashboard"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* CATALOGOS */}

        <SubMenu
            title={'Catalogos'}
            icon={<ListIcon/>}
        >
            {/* MESAS */}

            <Item
                title={'Mesas'}
                to={`/mesas`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* SUCURSALES */}

            <Item
                title={'Sucursales'}
                to={`/sucursales`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* CATEGORIAS */}

            <Item
                title={'Categorias'}
                to={`/categorias`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* INVENTARIO */}

            <Item
                title={'Inventario'}
                to={`/inventario`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* PROVEEDORES */}

            <Item
                title={'Proveedores'}
                to={`/proveedores`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* NOMINA EMPLEADOS */}

            <Item
                title={'Nominas'}
                to={`/nomina_empleados`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />

            {/* USUARIOS */}

            <Item
                title={'Usuarios'}
                to={`/usuarios`}
                icon={<ArrowRightIcon />}
                selected={selected}
                setSelected={setSelected}
            />
        
        </SubMenu>

        {/* ORDENES */}

        <Item
            title="Ordenes"
            to="/ordenes"
            icon={<LocalDiningIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* CORTE CAJA */}

        <Item
            title="Corte De Caja"
            to="/corte_caja"
            icon={<PointOfSaleIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* GASTOS */}

        <Item
            title="Gastos"
            to="/gastos"
            icon={<AddShoppingCartIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* RESUMEN DEL DIA */}

        <Item
            title="Resumen Dia"
            to="/resumen-del-dia"
            icon={<AssignmentIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* REPORTE */}

        <Item
            title="Reporte"
            to="/reporte"
            icon={<RequestQuoteIcon />}
            selected={selected}
            setSelected={setSelected}
        />
        
        </>

    )

}

export default AdminSidebar